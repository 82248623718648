import { v4 as uuidv4 } from 'uuid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import FormikContext from '../../../context/formikContext'
import EmailFieldFormik from '../../formik/fields/emailFieldFormik'
import OutlinedFieldFormik from '../../formik/fields/outlinedFieldFormik'
import Divider from '@mui/material/Divider'
import ContainedButton from '../../common/buttons/containedButton'
import SelectMultipleFormik from '../../formik/fields/selectMultipleFormik'
import TooltipRole from '../../../pages/entreprise/users/components/tooltipRole'
import { listRoles } from '../../../constants'
import { EnterprisesServerStateKeysEnum, useCreateUsers } from '../../../hooks/api/entreprises'
import { useContext, useState } from 'react'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'
import { UsersEnterpriseCreateType } from '../../../types/api/entreprise'
import { userEnterpriseSchema } from '../../../validations'
import { useQueryClient } from 'react-query'
import PlusIcon from '../../../icons/plusIcon'
import CloseIcon from '../../../icons/closeIcon'

const user = {
  email: '',
  first_name: '',
  last_name: '',
  roles: ['Utilisateur'],
}

const initialValues = {
  inviteList: [
    { id: uuidv4(), ...user },
  ],
}
interface InviteUsersFormProps {
  handleClose: () => void
  handleOpenSendInvitation: () => void
}

const InviteUsersForm = ({ handleClose, handleOpenSendInvitation }: InviteUsersFormProps) => {
  const [disabledSenButton, setDisabledSenButton] = useState(false)
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const query = useQueryClient()

  const formik = useFormik({
    initialValues,
    validationSchema: userEnterpriseSchema,
    onSubmit: async (values) => {
      setDisabledSenButton(true)
      const { inviteList } = values
      const data = {
        crm_id: activeEnterprise.crm_id,
        users: inviteList,
      }
      await mutateUsers(data as UsersEnterpriseCreateType, {
        onSuccess: async () => {
          handleClose()
          handleOpenSendInvitation()
          await query.invalidateQueries(EnterprisesServerStateKeysEnum.Users)
        },
        onError: () => {
          handleClose()
        },
      })
    },
  })

  const { mutate: mutateUsers } = useCreateUsers(formik)

  const hasManyInvitations = formik.values.inviteList.length > 1
  const handleDeleteUser = (index: number) => {
    const updatedInviteList = [...formik.values.inviteList]
    updatedInviteList.splice(index, 1)
    formik.setValues({
      inviteList: updatedInviteList,
    })
  }

  const handleAddUser = () => {
    formik.setValues({
      inviteList: [...formik.values.inviteList, { id: uuidv4(), ...user }],
    })
  }

  return (
    <Stack spacing='24px'>
      <Stack spacing='40px'>
        <Stack direction='row' spacing='2%'>
          <Typography variant='smallText' width='26%' sx={{ textTransform: 'uppercase' }}>
            Courriel*
          </Typography>
          <Typography variant='smallText' width='23%' sx={{ textTransform: 'uppercase' }}>
            Prénom*
          </Typography>
          <Typography variant='smallText' width='23%' sx={{ textTransform: 'uppercase' }}>
            Nom*
          </Typography>
          <Box sx={{ position: 'relative' }}>
            <Typography variant='smallText' sx={{ textTransform: 'uppercase' }}>
              Rôles*
              <TooltipRole sx={{ position: 'absolute', top: '-13px', left: 'calc(100% - 7px)' }} />
            </Typography>
          </Box>
        </Stack>
        <FormikContext.Provider value={formik}>
          {formik.values.inviteList.map((user, index) => (
            <Stack direction='row' key={user.id} spacing='2%' sx={{ alignItems: 'flex-start' }}>
              <EmailFieldFormik sx={{ width: '26%' }} name={`inviteList.${index}.email`} />
              <OutlinedFieldFormik
                sx={{ width: '23%' }}
                name={`inviteList.${index}.first_name`}
                placeholder={'Votre prénom'}
              />
              <OutlinedFieldFormik
                sx={{ width: '23%' }}
                name={`inviteList.${index}.last_name`}
                placeholder={'Votre nom de famille'}
              />
              <Box sx={{ width: '22%', display: 'flex', alignItems: 'center' }}>
                <SelectMultipleFormik
                  name={`inviteList.${index}.roles`}
                  disabledItems={['Utilisateur']}
                  listItems={listRoles}
                />

                <Box
                  component='button'
                  disabled={!hasManyInvitations}
                  sx={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    ml: '19px',
                    mr: '10px',
                    p: '0',
                    width: '14px',
                    height: '14px',
                    boxShadow: 'none',
                  }}
                  onClick={() => handleDeleteUser(index)}
                >
                  <Box
                    sx={{
                      '& svg path': {
                        fill: hasManyInvitations ? '#F9A51A' : '#b4b8b8',
                      },
                    }}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              </Box>
            </Stack>
          ))}
        </FormikContext.Provider>
      </Stack>
      <Button
        sx={{
          display: 'flex',
          width: 'auto',
          p: '0',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: 'white',
          },
          alignSelf: 'start',
          textTransform: 'inherit',
        }}
        onClick={handleAddUser}
      >
        <PlusIcon />
        <Typography
          variant='h5'
          sx={{
            textDecoration: 'underline',
            fontWeight: '700',
            ml: '15px',
          }}
        >
          Ajouter un autre utilisateur
        </Typography>
      </Button>
      <Divider />
      <ContainedButton
        sx={{ width: '256px', px: '16px', alignSelf: 'end' }}
        title='envoyer les invitations'
        disabled={disabledSenButton}
        handleClick={formik.handleSubmit}
      />
    </Stack>
  )
}

export default InviteUsersForm

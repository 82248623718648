import { useEffect, useState, useMemo, useContext } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import EditableTextField from '../common/fields/editableTextField'
import OutlinedFieldFormik from '../formik/fields/outlinedFieldFormik'
import Box from '@mui/material/Box'
import SectionForm from '../common/sectionForm'
import EditButtons from '../common/editButtons'
import FormikContext from '../../context/formikContext'
import { EditableContext } from '../../context/editableContext'
import { useGetCities, useGetCityByPostal } from '../../hooks/api/cities'
import AutoCompleteFormik from '../formik/fields/autoCompleteFormik'
import PhoneComponent from '../common/phoneComponent'
import EmailFieldFormik from '../formik/fields/emailFieldFormik'
import { getProvinces, isMember, getTitle } from '../../utils'
import useDebounce from '../../hooks/useDebounce'
import { t } from 'i18next'
import SelectFormik from '../formik/fields/selectFormik'
import NumberFieldFormik from '../formik/fields/numberFieldFormik'
import { useGetPostsTitle } from '../../hooks/api/postsTitle'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { Option } from '../../types'
interface Props {
  formik: any
  isEditable: boolean
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
}
const provinceQuebec = 'QC'
const PersonalInformationsForm = ({ formik, isEditable, setIsEditable }: Props) => {
  const [search, setSearch] = useState('')
  const [searchPost, setSearchPost] = useState('')
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const debouncedSearch = useDebounce(search, 500)
  const debouncedSearchPost = useDebounce(searchPost, 500)
  

  const { data: listTitles } = useGetPostsTitle()
  const { data: cities } = useGetCities(debouncedSearch, {
    enabled: formik.values.address.province === provinceQuebec && debouncedSearch.length > 2,
  })
  const { data: postes } = useGetCityByPostal(formik.values.address.city_id,debouncedSearchPost, {
    enabled: formik.values.address.province === provinceQuebec && debouncedSearchPost.length > 4,
  })
  const [openCodePoste, setOpenCodePoste] = useState(false)

  const [open, setOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  useEffect(() => {
    if (formik.initialValues.address.city_id !== formik.values.address.city_id) {
      setSearchPost('');
      formik.setFieldValue('address.postal_code', '')
    }
  }, [formik.values.address.city_id])
  useEffect(() => {
    if (formik.initialValues.address.province === provinceQuebec) {
      setSearch(formik.initialValues.address.city)
      setSearchPost(formik.initialValues.address.postal_code)
    }
  }, [formik.initialValues.address.province])
  useEffect(() => {
    if (!formik.values.address.province) {
      return
    }
    if (
      formik.initialValues.address.province !== provinceQuebec &&
      formik.values.address.province === provinceQuebec
    ) {
      formik.setFieldValue('address.city', '')
      formik.setFieldValue('address.postal_code', '')
      
    }
    if (
      !isUpdate &&
      formik.values.address.province !== provinceQuebec &&
      formik.values.address.province !== formik.initialValues.address.province
    ) {
      setIsUpdate(true)
      formik.setFieldValue('address.city_id', '')
      formik.setFieldValue('address.city', '')
      formik.setFieldValue('address.postal_code', '')
      
      return
    }
    if (isUpdate && formik.values.address.province !== provinceQuebec) {
      formik.setFieldValue('address.city_id', '')
      formik.setFieldValue('address.city', '')
      setSearch('')
      setSearchPost('')
     
    }
  }, [formik.values.address.province])




  const editableState = useMemo(() => ({ isEditable, setIsEditable }), [isEditable, setIsEditable])
  return (
    <EditableContext.Provider value={editableState}>
      <FormikContext.Provider value={formik}>
        <Box component='form' sx={{ pt: '48px', position: 'relative' }}>
          <Typography variant='h3' sx={{ mt: '18px', mb: '32px' }}>
            Mes coordonnées
          </Typography>
          <EditButtons sx={{ position: 'absolute', top: '48px', right: '0' }} />
          <Stack spacing='34px' sx={{ mb: '30px' }}>
            <SectionForm title='identité'>
              <Stack direction='row' spacing='24px' sx={{ mb: '24px' }}>
                <EditableTextField
                  label='Prénom'
                  required
                  sx={{ width: '318px' }}
                  value={formik.values.first_name}
                >
                  <OutlinedFieldFormik name='first_name' placeholder='Prénom' />
                </EditableTextField>
                <EditableTextField
                  label='Nom'
                  required
                  sx={{ width: '318px' }}
                  value={formik.values.last_name}
                >
                  <OutlinedFieldFormik name='last_name' placeholder='Nom' />
                </EditableTextField>
              </Stack>
              <Stack direction='row' spacing='24px' sx={{ mb: '24px' }}>
                <EditableTextField
                  label='Courriel'
                  required
                  sx={{ width: '318px' }}
                  value={formik.values.email}
                >
                  <EmailFieldFormik name='email' />
                </EditableTextField>
                {isMember(activeEnterprise) && (
                  <EditableTextField
                    label='Courriel de récupération'
                    sx={{ width: '318px' }}
                    value={formik.values.recovery_email}
                  >
                    <EmailFieldFormik name='recovery_email' />
                  </EditableTextField>
                )}
              </Stack>

              {isMember(activeEnterprise) && (
                <EditableTextField
                  sx={{ width: '204px' }}
                  label='Titre'
                  value={getTitle(formik.values.title, listTitles)}
                  required
                >
                  <SelectFormik
                    name='title'
                    placeholder='Titre'
                    fullWidth
                    listItems={(listTitles || []) as Option[]}
                  />
                </EditableTextField>
              )}
            </SectionForm>
            <SectionForm title='Téléphone principal'>
              <Stack direction='row' spacing='24px'>
                <PhoneComponent
                  object={formik.values.mainPhone}
                  typeName='mainPhone.type'
                  phoneName='mainPhone.phone_number'
                  postName='mainPhone.phone_number_post'
                  required
                />
              </Stack>
            </SectionForm>
            <SectionForm title='Autres numéros de téléphone'>
              <Stack direction='row' spacing='24px' sx={{ mb: '24px', alignItems: 'center' }}>
                <PhoneComponent
                  object={formik.values.otherPhones[0]}
                  typeName='otherPhones[0].type'
                  phoneName='otherPhones[0].phone_number'
                  postName='otherPhones[0].phone_number_post'
                />
              </Stack>
              <Stack direction='row' spacing='24px'>
                <PhoneComponent
                  object={formik.values.otherPhones[1]}
                  typeName='otherPhones[1].type'
                  phoneName='otherPhones[1].phone_number'
                  postName='otherPhones[1].phone_number_post'
                />
              </Stack>
            </SectionForm>
            <SectionForm title='adresse'>
              <Stack direction='row' spacing='24px' sx={{ mb: '24px' }}>
                <EditableTextField
                  label='No de rue'
                  value={formik.values.address.civic_number}
                  required
                  sx={{ width: '85px' }}
                >
                  <NumberFieldFormik name='address.civic_number' placeholder='No de rue' />
                </EditableTextField>
                <EditableTextField
                  label='Rue'
                  required
                  sx={{ width: '225px' }}
                  value={formik.values.address.street}
                >
                  <OutlinedFieldFormik name='address.street' placeholder='Rue' />
                </EditableTextField>
                <EditableTextField
                  label='Bureau, app.'
                  value={formik.values.address.office}
                  sx={{ width: '111px' }}
                >
                  <OutlinedFieldFormik
                    name='address.office'
                    placeholder='Bureau, app.'
                  />
                </EditableTextField>
              </Stack>
              <Box sx={{ display: 'flex' }}>
                <EditableTextField
                  label='Pays'
                  required
                  value={formik?.values?.address?.country || 'canada'}
                  sx={{ width: '334px', mr: '24px' }}
                >
                  <OutlinedFieldFormik disabled name='address.country' placeholder='Pays' />
                </EditableTextField>
                <EditableTextField
                  sx={{ width: '224px' }}
                  label='Province'
                  value={formik?.values?.address?.province ? t(`provinces.${formik.values.address.province}`) : ''}
                  required
                >
                  <SelectFormik
                    name='address.province'
                    placeholder='Province'
                    fullWidth
                    listItems={getProvinces() || []}
                  />
                </EditableTextField>
              </Box>

              <Stack direction='row' spacing='24px' sx={{ mt: '35px' }}>
                <EditableTextField
                  label='Ville'
                  required
                  value={formik.values.address.city}
                  sx={{ width: '334px' }}
                >
                  {formik.values.address.province === provinceQuebec ? (
                    <AutoCompleteFormik
                      name='address.city_id'
                      placeholder='Ville'
                      fullWidth
                      inputValue={search?search : formik.values.address.city }
                      listItems={cities || []}
                      open={open && search.length > 2 && Boolean(cities && cities.length > 0)}
                      onClose={() => setOpen(false)}
                      onOpen={() => {
                        setOpen(true)
                      }}
                      onInputChange={(e, newValue) => {
                        setSearch(newValue)
                      }}
                    />
                  ) : (
                    <OutlinedFieldFormik name='address.city' placeholder='Ville' fullWidth />
                  )}
                </EditableTextField>
                <EditableTextField
                  label='Code postal'
                  required
                  value={formik.values.address.postal_code}
                  sx={{ width: '111px' }}
                >
                  {formik.values.address.province === provinceQuebec ? (
                    <AutoCompleteFormik
                      name='address.postal_code'
                      placeholder='Code postal'
                      fullWidth
                      withName
                      inputValue={searchPost?searchPost : formik.values.address.postal_code }
                      listItems={postes || []}
                      open={openCodePoste && searchPost.length > 2 && Boolean(postes && postes.length > 0)}
                      onClose={() => setOpenCodePoste(false)}
                      onOpen={() => {
                        setOpenCodePoste(true)
                      }}
                      onInputChange={(e, newValue) => {
                        setSearchPost(newValue)
                      }}
                    />
                  ) : (
                    <OutlinedFieldFormik
                    name='address.postal_code'
                    placeholder='Code postal'
                    fullWidth
                  />
                  )}
                </EditableTextField>
              </Stack>
            </SectionForm>
          </Stack>
          <EditButtons sx={{ float: 'right' }} />
        </Box>
      </FormikContext.Provider>
    </EditableContext.Provider>
  )
}

export default PersonalInformationsForm
